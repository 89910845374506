import React, { Component } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { saveAs } from 'file-saver';
import { getImageUri, removeAnyCard, getArtChoices, saveArtChoices } from './Utilities.js'
import { renderCardChooser } from './CardChooser.js'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export class Home extends Component {
    static displayName = Home.name;
    static cardBackCount = 38;

    constructor(props) {
        super(props);
        this.state = {
            deckName: 'Red Hot Chilies', cardList: '', isPreview: false, cards: {}, isLoadingCards: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.switchToPreview = this.switchToPreview.bind(this);
        this.switchToEdit = this.switchToEdit.bind(this);
        this.downloadTtsDeck = this.downloadTtsDeck.bind(this);
        this.downloadTtsDeckThumbnail = this.downloadTtsDeckThumbnail.bind(this);
        this.setCardArtwork = this.setCardArtwork.bind(this);
        this.moveCardToCommandZone = this.moveCardToCommandZone.bind(this);
    }

    componentDidMount() {
        let cardList = localStorage.getItem('workInProgressCardList');
        const cardOutput = JSON.parse(localStorage.getItem('workInProgressCards'));
        const deckName = localStorage.getItem('workInProgressDeckName');
        const isPreview = cardOutput != null;

        if (cardList === null) {
            cardList = '';
        }

        if (isPreview) {
            this.applyCustomizationsToDeck(cardOutput);
        }

        this.setState({ cardList: cardList, cards: cardOutput, isPreview: isPreview, deckName: deckName });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    getDeckName(cards) {
        var name = 'Deck Name';

        if (cards == null) {
        } else if (cards.commandZoneCards !== null && cards.commandZoneCards.length > 0) {
            return cards.commandZoneCards[0].name;
        } else if (cards.deckCards !== null && cards.deckCards.length > 0) {
            var longest = 0;
            for (var i = 0; i < cards.deckCards.length; i++) {
                if (cards.deckCards[i].name.length > longest) {
                    name = cards.deckCards[i].name;
                    longest = name.length;
                }
            }
        }

        return name;
    }

    applyCustomizationsToCardList(preferredCards, cardList) {
        for (var i = 0; i < cardList.length; i++) {
            var customized = false;
            for (var j = 0; j < cardList[i].allIds.length; j++) {
                var stringId = cardList[i].allIds[j];
                if (preferredCards.has(stringId)) {
                    cardList[i].id = cardList[i].allIds[j];
                    customized = true;
                    break;
                }
            }

            if (!customized) {
                cardList[i].id = cardList[i].allIds[0];
            }
        }
    }

    applyCustomizationsToDeck(deck) {
        const artChoices = getArtChoices();

        const preferredCards = new Set();
        artChoices.map(c => preferredCards.add(c.id));

        this.applyCustomizationsToCardList(preferredCards, deck.cardBacks);
        this.applyCustomizationsToCardList(preferredCards, deck.commandZoneCards);
        this.applyCustomizationsToCardList(preferredCards, deck.deckCards);
        this.applyCustomizationsToCardList(preferredCards, deck.tokens);
        this.applyCustomizationsToCardList(preferredCards, deck.doubleSidedCards);
    }

    generateCardBacks(cardBackList) {
        var allIds = [];
        var names = [];
        var sortingCategories = [];
        var colors = []

        for (var i = 0; i < cardBackList.length; i++) {
            allIds.push(cardBackList[i].Slug);
            names.push(cardBackList[i].DisplayName);
            sortingCategories.push(cardBackList[i].SortingCategory);
            colors.push({
                backgroundColor: cardBackList[i].BackgroundColor,
                textColor: cardBackList[i].TextColor,
                borderColor: cardBackList[i].BorderColor
            });
        }

        var cardBack = {
            id: allIds[0],
            count: 1,
            doubleSided: false,
            allIds: allIds,
            names: names,
            sortingCategories: sortingCategories,
            colors: colors
        };

        return [cardBack];
    }

    async switchToPreview(event) {
        this.setState({
            isPreview: true,
            isLoadingCards: true
        });

        const response = await fetch('cardList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ cardList: this.state.cardList })
        });
        const data = await response.json();

        const cardBackResponse = await fetch('cardBackList');
        const cardBackList = await cardBackResponse.json();

        data.cardBacks = this.generateCardBacks(cardBackList);

        this.applyCustomizationsToDeck(data);

        const deckName = this.getDeckName(data);

        this.setState({ cards: data, isLoadingCards: false, deckName: deckName });

        localStorage.setItem('workInProgressCardList', this.state.cardList);
        if (this.state.isPreview && !this.state.isLoadingCards) {
            localStorage.setItem('workInProgressCards', JSON.stringify(this.state.cards));
            localStorage.setItem('workInProgressDeckName', this.state.deckName);
        } else {
            localStorage.removeItem('workInProgressCards');
            localStorage.removeItem('workInProgressDeckName');
        }
    }

    async downloadTtsDeck(event) {
        const response = await fetch('downloadDeck', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.cards)
        });

        const data = await response.json();
        this.saveData(data.file, this.state.deckName + '.json');
    }

    async downloadTtsDeckThumbnail(event) {
        const response = await fetch('downloadDeck', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.cards)
        });

        const data = await response.json();
        this.savePngData(data.deckImage, this.state.deckName + '.png');
    }

    saveData(data, fileName) {
        var blob = new Blob([JSON.stringify(data)], { type: "text/plain;charset=utf-8" });
        saveAs(blob, fileName);
    }

    savePngData(data, fileName) {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        var blob = new Blob([byteArray], { type: "image/png" });
        saveAs(blob, fileName);
    }

    switchToEdit(event) {
        this.setState({
            isPreview: false,
            isLoadingCards: false
        });
    }

    renderEditPage() {
        return (
            <div>
                <Button color="primary" className='btn-action' onClick={this.switchToPreview}>Choose Artwork</Button>
                <h3 id="tabelLabel" className='card-section-header' >Create deck</h3>
                <p>Input a list of cards, with one card name per line.</p>
                <p>The following formats are supported:</p>
                <div className='example-description'>Single card:</div>
                <div className='example-line'>Sol Ring</div>
                <div className='example-line'>Arcane Signet (ZNC) 106</div>
                <div className='example-description'>One or more cards:</div>
                <div className='example-line'>3 Plains</div>
                <div className='example-line'>6x Swamp</div>
                <div className='example-description'>Command-zone cards:</div>
                <div className='example-line'>* 1 Atraxa, Praetors' Voice</div>
                <div className='example-line'>Kenrith, the Returned King&nbsp;&nbsp;&nbsp;# Commander</div>
                <form className='card-input'>
                    <div>
                        <textarea cols='100' rows='40' name='cardList' value={this.state.cardList} onChange={this.handleInputChange} />
                    </div>
                </form>
            </div>
        );
    }

    renderCardStack(card, cardFaceIndex, count) {
        const items = [];

        const toggle = () => this.setState({
            modalCard: card, modalCardFaceIndex: cardFaceIndex, modalCompletionAction: this.setCardArtwork, modalTitle: 'Choose card artwork', modal: true, modalFilter: null });

        for (var i = 0; i < count; i++) {
            items.push(card);
        }

        return (
            <div className="card-stack">
                {items.map(item =>
                    (
                        <div className='img-parent'>
                            <LazyLoadImage className='img-rounded img-clickable' height={312} width={225} src={getImageUri(item.id, item.doubleSided, cardFaceIndex)} onClick={toggle} />
                        </div>
                    ))}
            </div>
        );
    }

    renderCardWithCorrectCount(card, cardFaceIndex) {
        const items = [];

        for (var i = 0; i < card.count; i += 4) {
            items.push(this.renderCardStack(card, cardFaceIndex, Math.min(4, card.count - i)));
        }

        return items;
    }

    totalCardCount(cardList) {
        var total = 0;
        for (var i = 0; i < cardList.length; i++) {
            total += cardList[i].count;
        }

        return total;
    }

    cardContainsId(card, cardId) {
        return card.allIds.includes(cardId);
    }

    moveCardToCommandZone(cardChoice, cardId, doubleSided) {
        for (var i = 0; i < this.state.cards.deckCards.length; i++) {
            if (this.cardContainsId(this.state.cards.deckCards[i], cardId)) {
                this.state.cards.commandZoneCards.push(this.state.cards.deckCards[i]);
                this.state.cards.deckCards.splice(i, 1);

                var deckName = this.state.deckName;
                if (this.state.cards.commandZoneCards.length === 1) {
                    deckName = this.state.cards.commandZoneCards[0].name;
                }

                this.setState({ cards: this.state.cards, modal: false, deckName: deckName });
                return;
            }
        }
    }

    canShowAddCard(showAddCard) {
        if (!showAddCard) {
            return false;
        }

        return this.getCandidateCardsForCommandZone().length > 0;
    }

    getCandidateCardsForCommandZone() {
        if (this.state.cards.commandZoneCandidates == null) {
            return [];
        }

        var existingCommandZone = new Set();

        for (var i = 0; i < this.state.cards.commandZoneCards.length; i++) {
            for (var j = 0; j < this.state.cards.commandZoneCards[i].allIds.length; j++) {
                existingCommandZone.add(this.state.cards.commandZoneCards[i].allIds[j]);
            }
        }

        var result = [];

        for (var k = 0; k < this.state.cards.commandZoneCandidates.length; k++) {
            if (!existingCommandZone.has(this.state.cards.commandZoneCandidates[k])) {
                const kConst = k;
                const fullCard = this.state.cards.deckCards.find(card => card.allIds.includes(this.state.cards.commandZoneCandidates[kConst]));

                result.push({
                    id: this.state.cards.commandZoneCandidates[k],
                    doubleSided: fullCard.doubleSided
                });
            }
        }

        return result;
    }

    renderAddCard(showAddCard) {
        if (!showAddCard) {
            return "";
        }

        var commandZoneCandidateCardIds = this.getCandidateCardsForCommandZone();

        if (commandZoneCandidateCardIds.length === 0) {
            return "";
        }

        var commandZoneChoiceCard = {
            id: '',
            count: 1,
            name: 'Command Zone',
            doubleSided: false,
            allIds: commandZoneCandidateCardIds
        }

        const toggle = () => this.setState({
            modalCard: commandZoneChoiceCard,
            modalCardFaceIndex: 0,
            modalCompletionAction: this.moveCardToCommandZone,
            modalTitle: "Move card from Main Deck to Command Zone",
            modal: true
        });

        if (showAddCard) {
            return (
                <div className='card-stack'>
                    <div className='img-parent'>
                        <LazyLoadImage className='img-clickable' height={312} width={225} src='https://scryfallcards.blob.core.windows.net/images/webui/AddCard.png' onClick={toggle} />
                    </div>
                </div>
            )
        } else {
            return "";
        }
    }

    renderZone(zoneCards, zoneName, cardFaceIndex, showAddCard, showCardCount) {
        var cardCountText = "";
        if (showCardCount) {
            cardCountText = " (" + this.totalCardCount(zoneCards) + ")";
        }

        if (zoneCards !== null && zoneCards !== undefined && ((zoneCards.length > 0 && zoneCards[0] !== undefined) || this.canShowAddCard(showAddCard))) {
            return (
                <div>
                    <h3 className='card-section-header'>{zoneName}{cardCountText}</h3>
                    <div className='card-list'>
                        {zoneCards.map(card => this.renderCardWithCorrectCount(card, cardFaceIndex)
                        )}
                        {this.renderAddCard(showAddCard)}
                    </div>
                </div>
            );
        } else {
            return "";
        }
    }

    renderCardBackZone() {
        var cardBack = {
            id: 'back0000',
            count: 1,
            doubleSided: false,
            allIds: ['back0000', 'back0001']
        };

        var zoneCards = [cardBack];

        return (
            <div>
                <h3 className='card-section-header'>Card Sleeve</h3>
                <div className='card-list'>
                    {zoneCards.map(card => this.renderCardWithCorrectCount(card, 1)
                    )}
                </div>
            </div>
        );
    }

    setCardArtwork(cardChoice, cardId, doubleSided) {
        cardChoice.id = cardId;

        var customizedCards = getArtChoices();
        removeAnyCard(cardChoice.allIds, customizedCards);
        customizedCards.push({ id: cardId, doubleSided: doubleSided });
        saveArtChoices(customizedCards);

        this.setState({ modal: false });
    }

    renderPreviewPage() {

        const modal = this.state.modal;
        const toggle = () => this.setState({ modal: !this.state.modal });

        const updateSearch = (event) => this.setState({ modalFilter: event.target.value });

        const clearSearch = (event) => {
            this.setState({ modalFilter: null });
            document.getElementById('input-filter-box').value = "";
        }

        let searchForm = "";
        if (this.state.modalCard !== undefined && this.state.modalCard !== null && this.state.modalCard.id !== undefined && this.state.modalCard.id !== null && this.state.modalCard.names !== undefined) {
            searchForm =
                <div className='input-filter-container'>
                    <InputGroup className="input-filter-group">
                        <Input placeholder="Filter..." onChange={updateSearch} className="input-filter" id="input-filter-box" value={this.state.modalFilter} />
                        <InputGroupAddon addonType="append">
                        <Button color="secondary" onClick={clearSearch}>&#215;</Button>
                        </InputGroupAddon>
                </InputGroup>
            </div>
        }

        return (
            <div>
                <Button color="secondary" className='btn-action' onClick={this.switchToEdit}>Back to List</Button>
                <label className='deckname-label'>
                    Deck name:
                </label>
                <input name='deckName' className='deckname-input' value={this.state.deckName} onChange={this.handleInputChange} />
                <span className='btn-action'><Button color="primary" onClick={this.downloadTtsDeck}>Download Deck</Button></span>
                <span className='btn-action'><Button color="secondary" className='btn-action' onClick={this.downloadTtsDeckThumbnail}>Download Thumbnail</Button></span>

                <div>
                    {this.state.cards.errors.map(error =>
                        <div className='error-message'>{error}</div>
                    )}
                </div>

                <div>
                    {this.renderZone(this.state.cards.cardBacks, 'Card Back', 0, false, false)}
                    {this.renderZone(this.state.cards.commandZoneCards, 'Command Zone', 0, true, true)}
                    {this.renderZone(this.state.cards.deckCards, 'Main Deck', 0, false, true)}
                    {this.renderZone(this.state.cards.tokens, 'Tokens', 0, false, true)}
                    {this.renderZone(this.state.cards.doubleSidedCards, 'Double-Sided Cards', 1, false, true)}
                </div>

                <Modal isOpen={modal} toggle={toggle} size='lg'>
                    <ModalHeader toggle={toggle}>{this.state.modalTitle}{searchForm}</ModalHeader>
                    <ModalBody>
                        <div>
                            {renderCardChooser(this.state.modalCard, this.state.modalCardFaceIndex, this.state.modalCompletionAction, this.state.modalFilter)}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        if (this.state.isLoadingCards) {
            return (
                <div>Loading...</div>
            );
        }
        else if (this.state.isPreview) {
            return this.renderPreviewPage(this.state.cards);
        } else {
            return this.renderEditPage();
        }
    }
}
