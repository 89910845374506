import React, { Component, Fragment } from 'react';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import OpenSeaDragon from "openseadragon";
import { OpenSeaDragonViewer } from './OpenSeaDragonViewer.js'
import './Artwork.css'

export class Artwork extends Component {
    static displayName = Artwork.name;

    constructor(props) {
        super(props);

        this.state = {
            targetViewport: null,
            isLoadingCards: true,
            mosaicLayout: { cards: [] },
            singleSelections: [],
            overlay: null
        }

        this.loadCardList();
    }

    async loadCardList() {
        const cardIndexUri = "https://scryfallcards.blob.core.windows.net/images/mosaic/nyx/jpg/nyx_cards.json";

        const cardBackResponse = await fetch(cardIndexUri);
        const mosaicLayout = await cardBackResponse.json();

        const cardNameToCardLookup = {};

        mosaicLayout.cardsByCell = {};

        for (var i = 0; i < mosaicLayout.cards.length; i++) {
            if (cardNameToCardLookup[mosaicLayout.cards[i].name] === undefined) {
                cardNameToCardLookup[mosaicLayout.cards[i].name] = mosaicLayout.cards[i];
                mosaicLayout.cards[i].isAmbiguous = false;
            } else {
                cardNameToCardLookup[mosaicLayout.cards[i].name].isAmbiguous = true;
                mosaicLayout.cards[i].isAmbiguous = true;
            }

            mosaicLayout.cards[i].set = mosaicLayout.sets[mosaicLayout.cards[i].setIndex];

            for (var x = 0; x < mosaicLayout.cards[i].size; x++) {
                for (var y = 0; y < mosaicLayout.cards[i].size; y++) {
                    var col = mosaicLayout.cards[i].coordinate.x + x;
                    var row = mosaicLayout.cards[i].coordinate.y + y;
                    const colRow = col + '_' + row;
                    mosaicLayout.cardsByCell[colRow] = mosaicLayout.cards[i];
                }
            }
        }

        mosaicLayout.cards.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                if (a.set.name < b.set.name) {
                    return -1;
                } else if (a.set.name > b.set.name) {
                    return 1;
                } else {
                    var collectorIdA = parseInt(a.collectorId);
                    var collectorIdB = parseInt(b.collectorId);

                    if (isNaN(collectorIdA) && isNaN(collectorIdB)) {
                        if (a.collectorId < b.collectorId) {
                            return -1;
                        } else if (a.collectorId > b.collectorId) {
                            return 1;
                        } else {
                            return 0;
                        }
                    } else if (isNaN(collectorIdA)) {
                        return -1;
                    } else if (isNaN(collectorIdB)) {
                        return 1;
                    } else {
                        return collectorIdA - collectorIdB;
                    }
                }
            }
        });

        this.setState({ mosaicLayout: mosaicLayout, isLoadingCards: false });
    }

    render() {

        const imageUri = "https://scryfallcards.blob.core.windows.net/images/mosaic/nyx/jpg/nyx.dzi";

        const animationFinished = (event) => {
            this.setState({
                targetViewport: null
            });
        };

        const viewportChanged = (event) => {
            const viewport = event.eventSource.viewport;
            const imageZoom = viewport.viewportToImageZoom(viewport.getZoom());
            if (imageZoom < 0.33) {
                this.setState({
                    overlay: null
                });
            }
        }

        const selectAndViewCard = (card) => {
            this.setState((state, props) => {
                if (!!card) {
                    if (!!state.overlay && state.overlay.key === card) {
                        return {
                            overlay: null,
                            singleSelections: []
                        };
                    }

                    const width = this.state.mosaicLayout.cellSize.width;
                    const height = this.state.mosaicLayout.cellSize.height;
                    const viewportPosition = new OpenSeaDragon.Point(card.coordinate.x * width + (width * card.size) / 2, card.coordinate.y * height + (height * card.size) / 2);
                    const viewportSize = new OpenSeaDragon.Point(width * 3.75, height * 3.75);

                    const cardX = card.coordinate.x * width;
                    const cardY = card.coordinate.y * height;

                    const overlay = {
                        key: card,
                        topLeft: new OpenSeaDragon.Point(cardX, cardY),
                        bottomRight: new OpenSeaDragon.Point((card.coordinate.x + card.size) * width, (card.coordinate.y + card.size) * height),
                        title: card.name + (!!card.fullName ? (" (" + card.fullName + ")") : ""),
                        subtitle: card.set.name + " #" + card.collectorId
                    };

                    const targetViewport = {
                        position: viewportPosition,
                        viewportSize: viewportSize
                    };

                    return {
                        overlay: overlay,
                        targetViewport: targetViewport,
                        singleSelections: [card]
                    };
                } else {
                    return {
                        overlay: null,
                        singleSelections: null
                    };
                }
            });
        };

        const setSingleSelections = (value) => {
            if (!!value && value.length > 0) {
                selectAndViewCard(value[0]);
            } else {
                selectAndViewCard(null);
            }
        };

        const renderMenuItemChildren = (option, { text }, index) => (
            <Fragment>
                <span>
                    <span className='artwork-card-name'>
                        <Highlighter search={text}>
                            {option.name}
                        </Highlighter>
                        {!!option.fullName &&
                            <span className='artwork-card-disambiguator'> ({option.fullName})</span>
                        }
                    </span>
                    {option.isAmbiguous &&
                        <small className='artwork-card-set'>{option.set.name} #{option.collectorId}</small>
                    }
                </span>
            </Fragment>
        );

        const filterByCallback = (option, props) => (
            option.name.toLowerCase().indexOf(props.text.toLowerCase()) === 0
        );

        const onImageCanvasClick = (imagePosition) => {
            const row = Math.floor(imagePosition.x / this.state.mosaicLayout.cellSize.width);
            const col = Math.floor(imagePosition.y / this.state.mosaicLayout.cellSize.height);
            const rowcol = row + '_' + col;
            const card = this.state.mosaicLayout.cardsByCell[rowcol];

            selectAndViewCard(card);
        };

        return (
            <Fragment>
                <div className='artwork-header'>
                    <h3>Artwork Browser</h3>

                    <p>Mosaic contains 26,263 unique card images released through Commander Legends. Full resolution: 57,420 x 22,990 pixels.</p>

                    <div className='artwork-search-group'>
                        <Typeahead
                            clearButton
                            id="basic-typeahead-single"
                            labelKey={(option) => `${option.name}`}
                            renderMenuItemChildren={renderMenuItemChildren}
                            filterBy={filterByCallback}
                            onChange={setSingleSelections}
                            options={this.state.mosaicLayout.cards}
                            minLength={1}
                            maxResults={15}
                            isLoading={this.state.isLoadingCards}
                            placeholder="Find a card..."
                            selected={this.state.singleSelections}
                        />
                    </div>
                </div>
                <div className="openseadragon">
                    <OpenSeaDragonViewer
                        image={imageUri}
                        targetViewport={this.state.targetViewport}
                        overlay={this.state.overlay}
                        onCanvasClick={onImageCanvasClick}
                        onAnimationComplete={animationFinished}
                        onViewportChange={viewportChanged} />
                </div>
            </Fragment>
        );
    }
}
