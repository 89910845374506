import React from 'react';
import { getImageUri } from './Utilities.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Highlighter from 'react-highlight-words';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

export function renderCardChooser(cardChoice, cardFaceIndex, onClick, searchString) {
    var searchStringLower;
    var searchTerms = [];
    if (searchString !== undefined && searchString !== null) {
        searchStringLower = searchString.toLowerCase();
        searchTerms = searchString.toLowerCase().split(/(\s+)/);
    }

    if (cardChoice !== null && cardChoice !== undefined) {
        var cards = [];
        for (var i = 0; i < cardChoice.allIds.length; i++) {

            var searchRank = 0;
            var sortingCategory = 0;
            var colors = { backgroundColor: 'auto', color: 'auto', borderColor: 'auto' };
            var name;
            if (cardChoice.names !== undefined) {
                name = cardChoice.names[i];
                sortingCategory = cardChoice.sortingCategories[i]
                colors = cardChoice.colors[i];
                searchRank = getSearchRank(searchStringLower, searchTerms, name);
            }

            if (searchRank >= 0) {
                cards.push({
                    id: cardChoice.allIds[i],
                    sortingCategory: sortingCategory,
                    name: name,
                    searchRank: searchRank,
                    index: i,
                    colors: colors
                });
            }
        }

        cards.sort((a, b) => {
            var rankCompare = a.searchRank - b.searchRank;
            if (rankCompare !== 0) {
                return rankCompare;
            }

            if (a.sortingCategory !== undefined && b.sortingCategory !== undefined) {
                rankCompare = a.sortingCategory - b.sortingCategory;
                if (rankCompare !== 0) {
                    return rankCompare;
                }
            }

            if (a.name !== undefined && b.name !== undefined) {
                return a.name.localeCompare(b.name);
            }

            return a.index - b.index;
        })

        if (cards.length === 0) {
            return (
                <div>No results found.</div>
            );
        } else {
            return (
                <div>
                    <div className='card-list'>
                        {cards.map(cardId => renderCardChoice(cardChoice, cardId, cardFaceIndex, onClick, searchTerms)
                        )}
                    </div>
                </div>
            );
        }
    } else {
        return "";
    }
}

function getSearchRank(searchString, searchTerms, candidate) {
    const candidateLower = candidate.toLowerCase();
    var isPrefix = false;
    var isExact = candidateLower.indexOf(searchString) >= 0;
    for (var i = 0; i < searchTerms.length; i++) {
        var index = candidateLower.indexOf(searchTerms[i])
        if (index < 0) {
            return -1;
        } else if (index === 0) {
            isPrefix = true;
        }
    }

    if (isPrefix && isExact) {
        return 0;
    } else if (isExact) {
        return 1;
    } else if (isPrefix) {
        return 2;
    } else {
        return 3;
    }
}

function renderCardChoice(cardChoice, cardIdOrGroup, cardFaceIndex, onClick, searchTerms) {
    if (cardIdOrGroup.id.id !== undefined) {
        return renderCardChoiceCard(cardChoice, cardIdOrGroup.id, cardIdOrGroup.id.doubleSided, cardFaceIndex, onClick);
    } else if (cardIdOrGroup.name === undefined) {
        return renderCardChoiceCard(cardChoice, cardIdOrGroup, cardChoice.doubleSided, cardFaceIndex, onClick);
    } else {
        return renderCardChoiceCardWithName(cardChoice, cardIdOrGroup, cardIdOrGroup.doubleSided, cardFaceIndex, searchTerms, onClick);
    }
}

function renderCardChoiceCard(cardChoice, cardId, doubleSided, cardFaceIndex, onClick) {
    const onClickLocal = () => {
        onClick(cardChoice, cardId.id, doubleSided);
    };

    return (
        <div className='card-stack'>
            <div className='img-parent'>
                <LazyLoadImage className='img-rounded img-clickable' height={312} width={225} placeholderSrc='https://scryfallcards.blob.core.windows.net/images/backs/standard/large.jpg' effect='black-and-white' src={getImageUri(cardId.id, doubleSided, cardFaceIndex)} onClick={onClickLocal} />
            </div>
        </div>
    );
}

function renderCardChoiceCardWithName(cardChoice, cardId, doubleSided, cardFaceIndex, searchTerms, onClick) {
    const onClickLocal = () => {
        onClick(cardChoice, cardId.id, doubleSided);
    };

    const divStyle = {
        backgroundColor: cardId.colors.backgroundColor,
        color: cardId.colors.textColor,
        borderColor: cardId.colors.borderColor,
        boxShadow: 'inset 0 0 10px ' + cardId.colors.borderColor
    };

    const imgStyle = {
        boxShadow: '0px -7px 6px -4px ' + cardId.colors.borderColor,
    };

    return (
        <div class='card-stack'>
            <div class='card-name' style={divStyle}><Highlighter highlightClassName="filter-highlight-text" searchWords={searchTerms} autoEscape={true} textToHighlight={cardId.name} /></div>
            <div class='img-parent'>
                <LazyLoadImage class='img-rounded img-clickable' style={imgStyle} height={312} width={225} placeholderSrc='https://scryfallcards.blob.core.windows.net/images/backs/standard/large.jpg' effect='black-and-white' src={getImageUri(cardId.id, doubleSided, cardFaceIndex)} onClick={onClickLocal} />
            </div>
        </div>
    );
}