import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { getImageUri, removeAnyCard, getArtChoices, saveArtChoices } from './Utilities.js'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export class Settings extends Component {
    static displayName = Settings.name;

    constructor(props) {
        super(props);

        this.state = { customCards: getArtChoices() }
    }

    renderIndividualCard(cardId, cardIsDoubleSided) {
        const onClick = () => {
            const artChoices = getArtChoices();
            removeAnyCard([cardId], artChoices);
            saveArtChoices(artChoices);

            this.setState({ customCards: artChoices });
        };

        return (
            <div className='card-stack'>
                <div className='img-parent'>
                    <LazyLoadImage className='img-rounded' height={312} width={225} src={getImageUri(cardId, cardIsDoubleSided, 0)} />
                </div>
                <div className='warning-button'>
                    <Button color="warning" onClick={onClick}>Remove</Button>
                </div>
            </div>
        );
    }

    render() {

        const clearAll = () => {
            localStorage.removeItem('artChoices');
            this.setState({ customCards: [] });
        }

        const hasCustomizations = this.state.customCards.length > 0;

        return (
            <div>
                <h3 className='card-section-header' >Artwork choices</h3>

                {!hasCustomizations &&
                    <>
                        <p>When you customize artwork, the artwork you choose will be used for the same card on future decks. That artwork will appear here, where you can remove it to restore using the default artwork for those cards.</p>
                        <p>You haven't customized any artwork yet.</p>
                    </>
                }

                {hasCustomizations &&
                    <>
                        <p>These cards are your preferred artwork when creating decks that contain those cards. You can remove individual cards or clear all customizations to resume using the default-selected artwork for those cards.</p>
                        <div className='danger-button'>
                            <Button color='danger' onClick={clearAll}>Remove All</Button>
                        </div>

                        <div>
                            {this.state.customCards.map(c =>
                                <div className='card-list'>
                                    {this.renderIndividualCard(c.id, c.doubleSided)}
                                </div>
                            )}
                        </div>
                    </>
                }
            </div>
        );
    }
}
