import OpenSeaDragon from "openseadragon";
import React, { Fragment } from "react";

export class OpenSeaDragonViewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOverlayVisible: false,
            overlayImageUri: ""
        };

        this.onOpened = this.onOpened.bind(this);
        this.onAnimationComplete = this.onAnimationComplete.bind(this);
        this.onCanvasClick = this.onCanvasClick.bind(this);
        this.onViewportChange = this.onViewportChange.bind(this);
    }

    onOpened(event) {
        if (this.props.targetViewport !== undefined && this.props.targetViewport !== null) {
            const center = event.eventSource.viewport.imageToViewportCoordinates(this.props.targetViewport.position);
            const zoom = event.eventSource.viewport.imageToViewportZoom(this.props.targetViewport.zoom);
            event.eventSource.viewport.panTo(center);
            event.eventSource.viewport.zoomTo(zoom);
        }
    }

    onAnimationComplete(event) {
        if (!!this.props.onAnimationComplete) {
            this.props.onAnimationComplete(event);
        }
    }

    onViewportChange(event) {
        if (!!this.props.onViewportChange) {
            this.props.onViewportChange(event);
        }
    }

    onCanvasClick(event) {
        if (event.quick) {
            event.preventDefaultAction = true;

            if (!!this.props.onCanvasClick) {
                const imageCoordinate = event.eventSource.viewport.viewerElementToImageCoordinates(event.position);
                this.props.onCanvasClick(imageCoordinate);
            }
        }
    }

    componentDidMount() {
        const viewer = OpenSeaDragon({
            id: "openSeaDragon",
            prefixUrl: "images/openseadragon/",
            tileSources: this.props.image,
            animationTime: 1.0,
            showNavigator: true,
            blendTime: 0.1,
            constrainDuringPan: false,
            maxZoomPixelRatio: 2,
            minZoomLevel: 1,
            visibilityRatio: 0.5,
            zoomPerScroll: 1.33
        });

        viewer.addHandler('animation-finish', this.onAnimationComplete);
        viewer.addHandler('canvas-click', this.onCanvasClick);
        viewer.addHandler('open', this.onOpened);
        viewer.addHandler('viewport-change', this.onViewportChange);

        this.setState({
            viewer: viewer
        });
    }

    componentWillUnmount() {
        this.state.viewer.destroy();
    }

    areTargetViewportsEqual(v1, v2) {
        if (v1 === undefined || v1 === null) {
            return v2 === undefined || v2 === null;
        } else if (v2 === undefined || v2 === null) {
            return false;
        } else {
            return v1.position.x === v2.position.x && v1.position.y === v2.position.y && v1.viewportSize.x === v2.viewportSize.x && v1.viewportSize.y === v2.viewportSize.y;
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.areTargetViewportsEqual(prevProps.targetViewport, this.props.targetViewport)) {
            if (this.props.targetViewport !== undefined && this.props.targetViewport !== null) {
                const topLeft = new OpenSeaDragon.Point(this.props.targetViewport.position.x - this.props.targetViewport.viewportSize.x / 2, this.props.targetViewport.position.y - this.props.targetViewport.viewportSize.y / 2);
                const bottomRight = new OpenSeaDragon.Point(this.props.targetViewport.position.x + this.props.targetViewport.viewportSize.x / 2, this.props.targetViewport.position.y + this.props.targetViewport.viewportSize.y / 2);
                const topLeftViewport = this.state.viewer.viewport.imageToViewportCoordinates(topLeft);
                const bottomRightViewport = this.state.viewer.viewport.imageToViewportCoordinates(bottomRight);
                this.state.viewer.viewport.fitBounds(new OpenSeaDragon.Rect(topLeftViewport.x, topLeftViewport.y, bottomRightViewport.x - topLeftViewport.x, bottomRightViewport.y - topLeftViewport.y));
            }
        }

        if (prevProps.overlay !== this.props.overlay) {
            if (!!this.props.overlay) {
                const clickedPointViewport = this.state.viewer.viewport.imageToViewportCoordinates(this.props.overlay.topLeft);
                const otherCornerViewport = this.state.viewer.viewport.imageToViewportCoordinates(this.props.overlay.bottomRight);
                const overlaySize = new OpenSeaDragon.Point(otherCornerViewport.x - clickedPointViewport.x, otherCornerViewport.y - clickedPointViewport.y);
                const viewportBounds = new OpenSeaDragon.Rect(clickedPointViewport.x, clickedPointViewport.y, overlaySize.x, overlaySize.y);

                const overlayId = 'openSeaDragonOverlay';
                var existingOverlay = this.state.viewer.getOverlayById(overlayId);
                if (!!existingOverlay) {
                    this.state.viewer.updateOverlay(overlayId, viewportBounds);
                } else {
                    this.state.viewer.addOverlay(overlayId, viewportBounds);
                }
            }
        }
    }

    render() {
        return (
            <Fragment>
                <div
                    id="openSeaDragon"
                >
                </div>
                <div id="openSeaDragonOverlay">
                    {!!this.props.overlay &&
                        <div className='openseadragon-map-overlay-box'>
                            <div className='openseadragon-map-overlay-label'>
                                <div className='openseadragon-map-overlay-captionbox'>
                                <p className='openseadragon-map-overlay-title'>{this.props.overlay.title}</p>
                                <p className='openseadragon-map-overlay-subtitle'>{this.props.overlay.subtitle}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        );
    }
}