export function getImageUri(cardId, doubleSided, frontBackIndex) {
    if (typeof cardId === 'string' && cardId.startsWith("back")) {
        return 'https://scryfallcards.blob.core.windows.net/images/backs/large/' + cardId + '.jpg';
    } else if (doubleSided) {
        return 'https://scryfallcards.blob.core.windows.net/images/large/' + cardId + '_' + frontBackIndex + '.jpg';
    } else {
        return 'https://scryfallcards.blob.core.windows.net/images/large/' + cardId + '.jpg';
    }
}

export function removeAnyCard(cardIdList, customizedCards) {
    for (var i = customizedCards.length - 1; i >= 0; i--) {
        for (var j = 0; j < cardIdList.length; j++) {
            if (customizedCards[i].id === cardIdList[j]) {
                customizedCards.splice(i, 1);
                break;
            }
        }
    }
}

export function getArtChoices() {
    return JSON.parse(localStorage.getItem('artChoices')) || [];
}

export function saveArtChoices(artChoices) {
    localStorage.setItem('artChoices', JSON.stringify(artChoices));
}